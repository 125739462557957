import React, { ReactNode } from "react";
import Footer from "./footer";
import Header from "./header";

interface LayoutProps {
   children: ReactNode;
}

const MainLayout: React.FC<LayoutProps> = ({ children }) => {
   return (
      <div id="root" className="flex flex-col min-h-screen bg-gray-100">
         <Header />
         <main className="flex-grow">
            <div className="p-8">
               <div className="container mx-auto">{children}</div>
            </div>
         </main>
         <Footer />
      </div>
   );
};

export default MainLayout;
