const Header = () => {
   return (
      <header className="bg-blue-500 p-4">
         <div className="container mx-auto flex items-center justify-between">
            <div className="text-white text-2xl font-bold">
               Lightning Sword Games
            </div>
            <nav>
               <ul className="flex space-x-4">
                  <li>
                     <a href="/" className="text-white hover:text-blue-200">
                        Home
                     </a>
                  </li>
                  <li>
                     <a
                        href="/privacy-policy"
                        className="text-white hover:text-blue-200"
                     >
                        Privacy Policy
                     </a>
                  </li>
                  <li>
                     <a
                        href="/app-ads.txt"
                        className="text-white hover:text-blue-200"
                     >
                        app-ads.txt
                     </a>
                  </li>
               </ul>
            </nav>
         </div>
      </header>
   );
};

export default Header;
