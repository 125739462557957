import React from "react";

interface ErrorProps {
   message: string;
}

const Error: React.FC<ErrorProps> = ({ message }) => {
   return (
      <div className="error-page">
         <h2>Oops! Something went wrong.</h2>
         <p>{message}</p>
      </div>
   );
};

export default Error;
