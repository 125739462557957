import { Helmet, HelmetProvider } from "react-helmet-async";
import MainLayout from "../components/main-layout";

const RootPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Lightning Sword Games</title>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link href="/dist/output.css" rel="stylesheet" />
      </Helmet>
      <MainLayout>
        <>
          Welcome to lightning sword games main website. We create a combination
          of small games and apps. <br />
          You can contact us by email at
          <br />
          support at lightningswordgames dot com
          <br />
        </>
      </MainLayout>
    </HelmetProvider>
  );
};

export default RootPage;
