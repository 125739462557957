const Footer = () => {
   return (
      <footer className="bg-blue-500 text-white p-4">
         <div className="container mx-auto text-center">
            &copy; {new Date().getFullYear()} Lightning Sword Games LLC. All
            rights reserved.
         </div>
      </footer>
   );
};

export default Footer;
