import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootPage from "./routes/root-page";
import PrivacyPolicyPage from "./routes/privacy-policy";
import NotFoundPage from "./routes/not-found-page";
import AppAdsPage from "./routes/app-ads";

const root = ReactDOM.createRoot(
   document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter(
   [
      {
         path: "/",
         element: <RootPage />,
         errorElement: <NotFoundPage />,
      },
      {
         path: "privacy-policy",
         element: <PrivacyPolicyPage />,
      },
      {
         path: "app-ads.txt",
         element: <AppAdsPage />,
      },
   ],
   { basename: `/${process.env.PUBLIC_URL}` }
);

root.render(
   <React.StrictMode>
      <RouterProvider router={router} />
   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
