import { useEffect, useState } from "react";

const AppAdsPage = () => {
   const [data, setData] = useState("");

   useEffect(() => {
      fetch("/app-ads.txt")
         .then((r) => r.text())
         .then((text) => {
            setData(text);
         });
   }, []);

   return (
      <div style={{ whiteSpace: "pre-wrap" }}>
         <p>{data}</p>
      </div>
   );
};

export default AppAdsPage;
