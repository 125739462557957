import { useEffect, useState } from "react";
import MainLayout from "../components/main-layout";

const PrivacyPolicyPage = () => {
   const [data, setData] = useState("");

   useEffect(() => {
      fetch("/privacy-policy.txt")
         .then((r) => r.text())
         .then((text) => {
            setData(text);
         });
   }, []);

   return (
      <MainLayout>
         <div style={{ whiteSpace: "pre-wrap" }}>
            <p>{data}</p>
         </div>
      </MainLayout>
   );
};

export default PrivacyPolicyPage;
